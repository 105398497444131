<template>
  <v-dialog v-model="visible" persistent max-width="800">
    <v-card class="pa-5 rounded-lg" id="card-custom">
      <v-btn @click="visible = false" x-small icon class="float-right">
        <v-icon>close</v-icon>
      </v-btn>
      <v-card-text class="subtitle-2 px-0 font-weight-regular">
        Catatan Kegiatan
      </v-card-text>
      <v-divider class="mb-4" />
      <v-form
        id="form"
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="save()"
      >
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >Tanggal</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-text-field
              :value="`${form.attendance_date}`"
              disabled
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >Nama/NIP</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-text-field
              :value="`${form.nama} - ${form.NIP}`"
              outlined
              dense
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >Catatan</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-textarea
              :placeholder="disabled ? '' : 'Tulis Catatan'"
              v-model="form.notes"
              outlined
              dense
              :rules="disabled ? [] : [v => !!v || 'Catatan harus diisi']"
              :disabled="disabled"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-card-actions class="mt-3" v-if="!disabled">
          <v-spacer />
          <v-btn
            small
            outlined
            color="#717171"
            class="px-12"
            @click="visible = false"
            >Batal</v-btn
          >
          <v-btn
            small
            color="#FBB005"
            class="px-12 subtitle-2 text-capitalize"
            :loading="formLoading"
            type="submit"
            :disabled="!valid"
            form="form"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import AttendanceService from "@/services/resources/attendance.service";

export default {
  data() {
    return {
      disabled: false,
      visible: false,
      valid: false,
      formLoading: false,
      types: [],
      form: {
        attendance_id: null,
        attendance_date: null,
        nama: null,
        NIP: null,
        notes: null
      }
    };
  },
  watch: {
    visible(val) {
      if (!val) {
        this.form.attendance_id = null;
        this.form.attendance_date = null;
        this.form.nama = null;
        this.form.NIP = null;
        this.form.notes = null;
        this.$refs.form.reset();
      }
    }
  },
  methods: {
    insert(item) {
      this.form.attendance_id = item.attendance_id;
      this.form.attendance_date = item.attendance_date;
      this.form.nama = item.nama;
      this.form.NIP = item.NIP;
      this.form.notes = item.notes;
      this.visible = true;
      this.disabled = false;
    },
    view(item) {
      this.form.attendance_id = item.attendance_id;
      this.form.attendance_date = item.attendance_date;
      this.form.nama = item.nama;
      this.form.NIP = item.NIP;
      this.form.notes = item.notes;
      this.visible = true;
      this.disabled = true;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$confirm({
          title: "Konfirmasi",
          message: `Apakah anda yakin akan merubah catatan?`,
          button: {
            no: "Batal",
            yes: "Ya"
          },
          callback: confirm => {
            if (confirm) {
              this.updateNotes({
                attendance_id: this.form.attendance_id,
                log_activity: this.form.notes
              });
            }
          }
        });
      }
    },
    // Service
    async updateNotes(payload) {
      try {
        this.formLoading = true;
        await AttendanceService.saveActivity(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.$emit("on-refresh");
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
<style lang="scss">
#btn-type .v-btn--contained {
  background-color: #ffffff !important;
  .v-btn__content {
    color: #717171 !important;
  }
}
#btn-type .v-btn--active {
  background-color: #1792e6 !important;
  .v-btn__content {
    color: #ffffff !important;
  }
}
</style>
